<!-- eslint-disable max-lines -->
<template>
    <div class="registration-todo-div">
        <list-layout-paper v-loading="btnloading" class="contract-registration-todo">
            <template slot="header-search" class="searchBox">
                <div class="header-box">
                <h4>合同续签进度登记</h4>
                <div class="header-part">
                    <!-- 按钮区域 -->
                    <div class="detail-toolBar">
                        <lots-button type="primary" @click="handleSave()" :loading="saveLoading">批量保存</lots-button>
                        <lots-button type="primary" @click="handleFinish" :loading="handleFinishLoading">完成待办</lots-button>
                        <lots-button type="primary" @click="closePage">关闭页面</lots-button>
                    </div>
                    <div class="header-info-crm">
                        <div class="apply-info">
                            <div>单据号：{{ detailDataLocal.fdFlowId }}</div>
                            <div>待办人：{{ detailDataLocal.fdSendersAccount }}</div>
                            <div>任务开始日期：{{ detailDataLocal.fdCreateTime }}</div>
                            <div>任务截止日期：{{ detailDataLocal.fdExpireDate }}</div>
                        </div>
                    </div>
                </div>
                <search-box
                    ref="searchBox"
                    :search-key="config.name"
                    :fields="config.searchFields"
                    :showResetBtn="true"
                    @search-change="accountList.searchList"
                />
                <el-menu
                    :default-active="isFull"
                    class="el-menu-demo"
                    mode="horizontal"
                    @select="handleSelect">
                    <el-menu-item index="N">待处理</el-menu-item>
                    <el-menu-item index="Y">全量</el-menu-item>
                </el-menu>
            </div>
            </template>
            <template v-slot:list="{ tableHeight }">
                <edit-table-box
                    ref="editTableBox"
                    v-loading="accountList.tableLoading"
                    :control="true"
                    :selection="config.selection"
                    :columns="config.columns"
                    :height="tableHeight"
                    :tableData="accountList.totalData"
                    :actions="config.actions"
                    :allowRowClick="true"
                    @selection-change="accountList.handleSelectedRow"
                    @element-change="handleChangeRow"
                    @btn-event="actionClick">
                    <template #col-header="{ item }">
                        <span v-if="item.prop === 'renewalRisk'">
                            {{ item.label }}
                            <el-tooltip effect="dark" placement="top">
                                <i class="el-icon-question"></i>
                                <template slot="content">
                                    中高风险需要同步营销中心，拉群同步项目推动计划，协调各方解决此风险项目处理<br/>
                                </template>
                            </el-tooltip>
                        </span>
                        <span v-else-if="item.prop === 'riskCause'">
                            {{ item.label }}
                            <el-tooltip effect="dark" placement="top">
                                <i class="el-icon-question"></i>
                                <template slot="content">
                                    风险原因超过2项时，建议标识为高风险<br/>
                                </template>
                            </el-tooltip>
                        </span>
                        <span v-else-if="item.prop === 'renewalContractor'">
                            {{ item.label }}
                            <el-tooltip effect="dark" placement="top">
                                <i class="el-icon-question"></i>
                                <template slot="content">
                                    续签承接人每月5号/20号会收到待办<br/>
                                </template>
                            </el-tooltip>
                        </span>
                        <span v-else>{{ item.label }}</span>
                    </template>
                    <template #col-append="{item, row, rIndex}">
                        <span v-if="item.prop === 'customerLevel'">{{ codesToNames(row[item.prop], dictData[item.optionsKey]) }}</span>
                        <span v-else-if="item.prop === 'renewalRisk'" :class="[['1'].includes(row[item.prop] + '') ? 'red-color' : '']">
                            {{ codesToNames(row[item.prop], dictData[item.optionsKey]) }}
                        </span>
                        <lots-button v-else-if="item.prop === 'file'" type="text"  @click.stop="handleOpen(row)">查看附件</lots-button>
                    </template>
                    <template #edit-col-append="{item, row, rIndex}">
                        <span v-if="item.prop === 'customerLevel'">{{ codesToNames(row[item.prop], dictData[item.optionsKey]) }}</span>
                        <lots-button v-else-if="item.prop === 'file'" type="text" @click.stop="handleOpen(row)">编辑附件</lots-button>
                    </template>
                </edit-table-box>
            </template>
            <template slot="footer">
                <lots-pagination
                    @size-change="accountList.sizeChange"
                    :current-page.sync="accountList.pageNo"
                    @current-change="accountList.pageChange"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="accountList.pageTotal" >
                </lots-pagination>
            </template>
        </list-layout-paper>
        <add-file-dialog
            v-if="addFileVisible"
            :fileReadonly="fileReadonly"
            :row="theRow"
            @confirm="dialogConfirm"
            @handleClose="addFileVisible = false">
        </add-file-dialog>
    </div>
</template>
<script>
import { ref, reactive, onActivated, computed, getCurrentInstance, onMounted } from '@vue/composition-api';
import listLayoutPaper from '@/components/lots/listLayout/Paper';
import searchBox from '@/components/lots/searchBox';
import lotsButton from '@/components/lots/lotsButton';
import { tableConfig } from './config.js';
import editTableBox from '@/components/lots/editTableBox/Index';
import lotsPagination from '@/components/lots/lotsPagination/Index';
import { queryContractRenewalByUserCode, contractRenewalRegistrationSave, setHaveDoneApi } from '@mdm/api/contractManage/contractRegistration.js';
import { AdvanceListClass } from '@/modules/mdm/composition/dc/common/list/AdvanceListClass';
import { Message } from 'element-ui';
import store from '@/store/index';
import utils from '@/components/utils/common.js';
import _ from 'lodash';
import { getDictDataApi } from '@mdm/api/contractManage/contractListApi.js';
import addFileDialog from '@/modules/mdm/views/contractModules/components/addFileDialog/Index';
import dayjs from 'dayjs';
const { MAGIC_NUMBER } = utils;
export default {
    name: 'contractRegistrationToDo',
    components: { listLayoutPaper, searchBox, lotsButton, editTableBox, lotsPagination, addFileDialog },
    props: {
        detailPageState: {
            type: String,
            default() {
                return '';
            }
        },
        detailData: {
            type: Object,
            return: () => { }
        }
    },
    // eslint-disable-next-line max-lines-per-function
    setup(props, setupContext) {
        const EDIT_TYPE = {
            EDIT: 2,
            CANCEL: -3
        };
        const ctx = getCurrentInstance(); // 通过该方法访问当前组件的实例对象,相当于this
        const config = _.cloneDeep(tableConfig);
        const btnloading = ref(false);
        const detailLoading = ref(false);
        const isFull = ref('N');
        const { user } = store.getters;
        const detailDataLocal = reactive({
            todoType: '',
            fdFlowId: '',
            fdSendersAccount: '',
            fdCreateTime: '',
            fdExpireDate: ''
        });
        class AccountListClass extends AdvanceListClass {
            beforeGetList(condition) {
                return searchDataFormat(condition);
            };
            afterGetList (res) {
                if (+res.code === 0) {
                    this.totalData.value = res.data.list.map((v, index) => {
                        v.edit = -3;
                        v.allowEdit = true;
                        v.riskCause && v.riskCause.length && (v.riskCause = v.riskCause.split(','));
                        v.causeCategories = v.causeCategories || '';
                        v.causeSubcategory = v.causeSubcategory || '';
                        v.causeCategoriesOptions = dictData.value['CRM_CONTRACT_CAUSE_CATEGORIES'];
                        v.causeSubcategoryOptions = dictData.value['CRM_CONTRACT_CAUSE_SUBCATEGORY'];
                        return v;
                    });
                    this.pageTotal.value = res.data.totalCount;
                    detailDataLocal.fdSendersAccount = res.data.fdSendersAccount;
                    detailDataLocal.fdCreateTime = res.data.fdCreateTime;
                    detailDataLocal.fdExpireDate = res.data.fdExpireDate;
                    setTimeout(() => {
                        accountList.totalData.value.forEach((item, index) => {
                            disableAndMustFillFn(item);
                        });
                    });
                }
            };
        };
        const accountList = new AccountListClass({
            Api: {
                listApi: queryContractRenewalByUserCode // 查询接口
            }
        });
        const refleshPage = () => {
            accountList.getList();
        };
        const closePage = () => {
            ctx.$router.go(-1);
        };
        const handleSelect = (key) => {
            isFull.value = key;
            accountList.getList();
        };
        const currentMonthSart = dayjs().startOf('month').valueOf(); // 获取当前时间的月份第一天时间戳
        // eslint-disable-next-line complexity
        const handleChangeRow = _.debounce(({ prop, val, type, row, index }) => {
            if (prop === 'renewalStatus') { // 续签状态：1洽谈中 2已续签 3已终止
                if (val === '1') {
                    row[prop] = '';
                    row[prop] = '1'; // 不知为什么，选1洽谈中时，编辑表格不会触发内部禁用函数（_columnIfDisabled）自动计算，需要手动改值才会触发
                }
            } else if (['upstreamAmplitude', 'downstreamAmplitude'].includes(prop)) {
                row[prop] = val.replace(/\s*/g, '');
                const strData = val.replace(/\s*/g, '') || 0;
                if (!Number.isFinite(+strData)) {
                    row[prop] = '';
                    return Message.warning('请输入正确的数字值');
                }
            } else if (prop === 'exitStatus') {
                if (val) {
                    row['causeCategories'] = '';
                    row['causeSubcategory'] = '';
                    const newOptions = {};
                    Object.keys(dictData.value['CAUSE_CATEGORIES_TO_EXIT_STATUS']).forEach(key => {
                        if (dictData.value['CAUSE_CATEGORIES_TO_EXIT_STATUS'][key] === val) {
                            newOptions[key] = dictData.value['CRM_CONTRACT_CAUSE_CATEGORIES'][key];
                        }
                    });
                    row[`causeCategoriesOptions`] = newOptions;
                } else {
                    row[`causeCategoriesOptions`] = dictData.value['CRM_CONTRACT_CAUSE_CATEGORIES'];
                }
            } else if (prop === 'causeCategories') {
                if (val) {
                    row['causeSubcategory'] = '';
                    const newOptions = {};
                    Object.keys(dictData.value['CAUSE_SUBCATEGORY_TO_CATEGORIES']).forEach(key => {
                        if (dictData.value['CAUSE_SUBCATEGORY_TO_CATEGORIES'][key] === val) {
                            newOptions[key] = dictData.value['CRM_CONTRACT_CAUSE_SUBCATEGORY'][key];
                        }
                    });
                    row[`causeSubcategoryOptions`] = newOptions;
                } else {
                    row[`causeSubcategoryOptions`] = dictData.value['CRM_CONTRACT_CAUSE_SUBCATEGORY'];
                }
            } else if (prop === 'earlyTerminationTime') {
                // 提前终止时间小于本月
                const isBefore = val && (dayjs(val).valueOf() < currentMonthSart);
                isBefore && Message.warning('“提前终止时间”小于本月时，“外部业务退出报告流程链接”字段必填');
            }
            disableAndMustFillFn(row);
        }, MAGIC_NUMBER.FIVE_HUNDRED);
        // 行必填、禁用规则处理函数：
        // eslint-disable-next-line max-lines-per-function, complexity
        const disableAndMustFillFn = (row) => {
            const isRisk = ['1', '2'].includes(row.renewalRisk + ''); // 风险等级为：高中风险
            const noMip = !row.renewalContractorMip; // 续签承接人为空
            const causeSubcategoryFlag = ['8', '9'].includes(row.causeSubcategory + ''); // 终止原因小类：包含8到期涨价业务丢失、9投标竞价失败"
            // 提前终止时间小于本月
            const isBefore = row.earlyTerminationTime && (dayjs(row.earlyTerminationTime).valueOf() < currentMonthSart);
            // “提前终止时间”小于本月时，“外部业务退出报告流程链接”字段必填
            switch (+row.renewalStatus) {
            case 1: // 续签状态：1洽谈中
                row.disableProps = [
                    'terminationCause',
                    'exitStatus',
                    'causeCategories',
                    'causeSubcategory',
                    'processLink',
                    'earlyTerminationTime'
                ];
                if (isRisk) {
                    row.mustFillProps = ['renewalRisk', 'riskCause', 'projectSchedule']; // （高、中风险：风险原因riskCause必填）
                } else {
                    row.mustFillProps = ['renewalRisk', 'projectSchedule'];
                }
                break;
            case 2: // 续签状态：2已续签
                row.disableProps = [
                    'renewalRisk',
                    'riskCause',
                    'terminationCause',
                    'exitStatus',
                    'causeCategories',
                    'causeSubcategory',
                    'processLink',
                    'earlyTerminationTime'
                ];
                row.mustFillProps = [];
                break;
            case 3: // 续签状态：3已终止
                row.disableProps = ['renewalRisk', 'riskCause'];
                if (causeSubcategoryFlag) {
                    if (isBefore) {
                        row.mustFillProps = [
                            'terminationCause',
                            'exitStatus',
                            'upstreamRenewal',
                            'upstreamAmplitude',
                            'downstreamRenewal',
                            'downstreamAmplitude',
                            'earlyTerminationTime',
                            'causeCategories',
                            'causeSubcategory',
                            'processLink'
                        ];
                    } else {
                        row.mustFillProps = [
                            'terminationCause',
                            'exitStatus',
                            'upstreamRenewal',
                            'upstreamAmplitude',
                            'downstreamRenewal',
                            'downstreamAmplitude',
                            'earlyTerminationTime',
                            'causeCategories',
                            'causeSubcategory'
                        ];
                    }
                } else {
                    if (isBefore) {
                        row.mustFillProps = [
                            'terminationCause',
                            'exitStatus',
                            'earlyTerminationTime',
                            'causeCategories',
                            'causeSubcategory',
                            'processLink'
                        ];
                    } else {
                        row.mustFillProps = [
                            'terminationCause',
                            'exitStatus',
                            'earlyTerminationTime',
                            'causeCategories',
                            'causeSubcategory'
                        ];
                    }
                }
                break;
            default: // 续签状态为空
                row.disableProps = [];
                // 续签承接人为空：续签状态renewalStatus必填；高中风险：风险原因riskCause必填
                // 续签承接人不为空：续签状态renewalStatus非必填；高中风险：风险原因riskCause必填
                if (noMip) {
                    if (isRisk) {
                        // eslint-disable-next-line max-depth
                        if (isBefore) {
                            row.mustFillProps = ['renewalStatus', 'riskCause', 'processLink'];
                        } else {
                            row.mustFillProps = ['renewalStatus', 'riskCause'];
                        }
                    } else {
                        // eslint-disable-next-line max-depth
                        if (isBefore) {
                            row.mustFillProps = ['renewalStatus', 'processLink'];
                        } else {
                            row.mustFillProps = ['renewalStatus'];
                        }
                    }
                } else {
                    if (isRisk) {
                        // eslint-disable-next-line max-depth
                        if (isBefore) {
                            row.mustFillProps = ['riskCause', 'processLink'];
                        } else {
                            row.mustFillProps = ['riskCause'];
                        }
                    } else {
                        // eslint-disable-next-line max-depth
                        if (isBefore) {
                            row.mustFillProps = ['processLink'];
                        } else {
                            row.mustFillProps = [];
                        }
                    }
                }
                break;
            }
            row.disableProps.forEach((key) => { // 清空禁用字段的值
                if (Array.isArray(row[key])) {
                    row[key] = [];
                } else {
                    (row[key] || row[key] === 0) && (row[key] = '');
                }
            });
        };
        const actionClick = (row, event, index) => {
            if (event === 'edit') {
                disableAndMustFillFn(row);
                handleChangeRow({ prop: 'exitStatus', val: row['exitStatus'], row });
            } else if (event === 'saveRow') {
                handleSave([row]);
            }
        };
        const searchDataFormat = (condition = {}, params = {}) => {
            if (condition.failTime && condition.failTime.length) {
                condition.startFailTime = utils.formatDateTime(condition.failTime[0]);
                condition.endFailTime = utils.formatDateTime(condition.failTime[1]);
                Reflect.deleteProperty(condition, 'failTime');
            }
            const { todoType, fdFlowId, flowId } = ctx.$route.query;
            condition.todoType = todoType || '1';
            condition.fdFlowId = fdFlowId || flowId || '';
            condition.isFull = isFull.value;
            condition.userCode = user.userCode;
            Object.keys(condition).forEach(v => {
                !condition[v] && condition[v] !== 0 && (condition[v] = undefined);
            });
            return { ...condition, ...params };
        };
        // 校验表格数据，返回必填未填的信息
        const checkTableDataFillFn = (tableData) => {
            // 找出必填未填的行数据：
            const notFillRows = tableData.filter(item => item.mustFillProps && item.mustFillProps.length
                && item.mustFillProps.some(key => !item[key] && item[key] !== 0));
            const notFillMsgs = [];
            notFillRows.forEach(item => {
                const notFillNames = [];
                item.mustFillProps.forEach(key => {
                    if (key === 'riskCause' && (!item[key] || !item[key].length)) {
                        const colItem = config.columns.find(col => col.prop === key);
                        colItem && (notFillNames.push(colItem.label));
                    } else if (!item[key] && item[key] !== 0) {
                        const colItem = config.columns.find(col => col.prop === key);
                        colItem && (notFillNames.push(colItem.label));
                    }
                });
                const newNames = '单据编号：' + item.contractCode + '，' + notFillNames.join('、') + ' 必填；';
                notFillMsgs.push(newNames);
            });
            return notFillMsgs;
        };
        const saveLoading = ref(false);
        // eslint-disable-next-line max-lines-per-function
        const handleSave = (saveList = accountList.selectedRows.value) => {
            if (!saveList.length) {
                Message.warning('请勾选要保存的数据');
                return false;
            } else {
                const checkTableData = _.cloneDeep(saveList).map(item => {
                    const newItem = _.omit(item, ['backup', 'edit', 'allowEdit', 'disableProps', 'causeCategoriesOptions', 'causeSubcategoryOptions']); // 删除某些属性
                    newItem.saveType = 'todoPage';
                    newItem.riskCause && (newItem.riskCause = item.riskCause.join(','));
                    return newItem;
                });
                if (!setupContext.refs.editTableBox.tableExamine(checkTableData)) { // 编辑表格数据校验
                    return false;
                };
                const notFillMsgs = checkTableDataFillFn(checkTableData);
                if (notFillMsgs.length) {
                    const msgHTLM = '<p>' + notFillMsgs.join('</p><p>') + '</p>';
                    Message({
                        dangerouslyUseHTMLString: true,
                        showClose: true,
                        message: msgHTLM,
                        type: 'warning',
                        duration: 5000
                    });
                    return false;
                }
                if (checkTableData.some(item => ['1', '2'].includes(item.renewalRisk + ''))) {
                    Message({
                        dangerouslyUseHTMLString: true,
                        showClose: true,
                        message: `<p>1、中高风险项目需拉群同步营销中心，协调各方推动项目续签。</p>
                        <p>2、严格实行“先决策后投标”。TOP 客户存量有丢失风险，要求按分权手册，由营销中心总监/大件产业部总监、专司总、行业负责人、督导与分公司经理就项目投标策略进行决策，并按最终决策意见投标/报价。</p>
                        <p>普通客户（仓配≥100 万/仓干≥200 万）存量有丢失风险，由督导、行业负责人与分公司经理就项目投标策略进行决策，按最终决策意见投标/报价。</p>`,
                        type: 'warning',
                        duration: 10000
                    });
                }
                saveLoading.value = true;
                contractRenewalRegistrationSave(checkTableData).then(res => {
                    if (+res.code === 0) {
                        Message.success('保存成功');
                        accountList.getList();
                    }
                }).finally(() => {
                    saveLoading.value = false;
                });
            }
        };
        const handleFinishLoading = ref(false);
        const handleFinish = () => {
            if (accountList.totalData.value.some(item => item.edit === 2)) {
                Message.warning('存在编辑为保存的数据，请先保存后再操作');
                return false;
            }
            handleFinishLoading.value = true;
            setHaveDoneApi({ fdFlowId: detailDataLocal.fdFlowId }).then((res) => {
                if (+res.code === 0) {
                    Message.success('完成待办成功');
                }
            }).finally(() => { handleFinishLoading.value = false; });
        };
        const codesToNames = computed(() => {
            function codeToName(codes, dictObj) {
                const names = [];
                const codesArr = codes ? codes.split(',') : [];
                if (codesArr && codesArr.length) {
                    codesArr.forEach((key) => {
                        if (dictObj && dictObj[key]) {
                            names.push(dictObj[key]);
                        }
                    });
                }
                return names.join(',');
            };
            return codeToName;
        });
        const addFileVisible = ref(false);
        const fileReadonly = ref(false);
        const theRow = ref({});
        const handleOpen = (row) => {
            if (row.edit === EDIT_TYPE.CANCEL) {
                // Message.warning([0, 1].includes(+row.contractStatus) ? '请点击编辑按钮开启编辑状态进行编辑' : '非草稿、新增状态，不允许编辑');
                fileReadonly.value = true;
            } else {
                fileReadonly.value = false;
            }
            theRow.value = row;
            addFileVisible.value = true;
        };
        const dialogConfirm = (data) => {
            theRow.value.edit !== EDIT_TYPE.CANCEL && (theRow.value.file = data);
            addFileVisible.value = false;
        };
        onActivated(() => {
            setupContext.refs.editTableBox.$refs.moduleTable.doLayout(); // 解决表格错位
        });
        const dictData = ref({});
        onMounted(async() => {
            const { todoType, fdFlowId, flowId } = ctx.$route.query;
            detailDataLocal.todoType = todoType || '1';
            detailDataLocal.fdFlowId = fdFlowId || flowId || '';
            // eslint-disable-next-line max-len
            const res = await getDictDataApi({ dictCodes: 'SYS_WM_CUSTOMER_LEVEL,SYS_BMS_BUSI_TYPE,CRM_CONTRACT_RENEWAL_STATUS,CRM_CONTRACT_RENEWAL_RISK,CRM_CONTRACT_CAUSE_CATEGORIES,CRM_CONTRACT_CAUSE_SUBCATEGORY,CAUSE_CATEGORIES_TO_EXIT_STATUS,CAUSE_SUBCATEGORY_TO_CATEGORIES' });
            dictData.value = res.data;
            const queryParams = {
                failTime: utils.defaultDate('-90') // 默认选中前15天(当前日期23.59.59 到15天前的0.0.0)时间范围
            };
            setupContext.refs.searchBox.setQueryParams(queryParams);
            setupContext.refs.searchBox.submitSearch();
            // accountList.getList();
        });
        return {
            config,
            btnloading,
            detailDataLocal,
            detailLoading,
            refleshPage,
            isFull,
            handleSelect,
            accountList,
            handleChangeRow,
            closePage,
            saveLoading,
            handleSave,
            handleFinish,
            handleFinishLoading,
            actionClick,
            codesToNames,
            dictData,
            addFileVisible,
            fileReadonly,
            theRow,
            handleOpen,
            dialogConfirm
        };
    }
};
</script>
<style lang="less">
.registration-todo-div {
    width: 100%;
    height: 100%;
    .contract-registration-todo {
        height: 100%;
        .header-box {
            width: 100%;
            h4 {
                text-align: center;
                font-size: 20px;
                margin: 0;
                color: #606266;
                border-bottom: 1px solid #dde0e6;
                margin-bottom: 10px;
                padding-bottom: 5px;
            }
        }
        .header-part {
            display: flex;
            justify-content: space-between;
            height: 40px;
            .detail-toolBar {
                flex: 1;
                .item-flow-right {
                    // float: right;
                    &.refresh {
                        margin-left: 10px;
                    }
                }
            }
            .header-info-crm {
                font-size: 14px;
                .apply-info {
                    width: 330px;
                    line-height: 20px;
                    color: #606266;
                }
            }
        }
        .el-menu.el-menu--horizontal {
            width: 150px;
            .el-menu-item {
                height: 35px;
                line-height: 35px;
            }
        }
        .list-layout {
            .list-header {
                margin-bottom: 0;
            }
            .list-main {
                .edit-table-box {
                    height: 100%;
                }
            }
        }
        .red-color {
            color: red;
        }
    }
}
</style>
