const EDIT_TYPE = {
    EDIT: 2,
    CANCEL: -3
};
export const tableConfig = {
    name: 'contractRegistrationToDo',
    searchFields: [
        {
            name: '失效日期',
            value: 'failTime',
            type: 'time',
            time: true,
            startPlaceholder: '合同失效日期-开始',
            endPlaceholder: '合同失效日期-结束',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            isFixed: true,
            span: 6
        }
    ],
    selection: true,
    columns: [
        {
            label: '单据编号',
            prop: 'contractCode',
            width: 130,
            fixed: 'left'
        },
        {
            label: '片区',
            prop: 'region',
            width: 70,
            fixed: 'left'
        },
        {
            label: '分公司',
            prop: 'companyName',
            width: 100,
            fixed: 'left'
        },
        // {
        //     label: '分公司编码',
        //     prop: 'companyCode',
        //     width: 140
        // },
        {
            label: '客户编码',
            prop: 'customerCode',
            width: 95,
            sort: true,
            fixed: 'left'
        },
        {
            label: '客户名称',
            prop: 'customerName',
            width: 95,
            sort: true,
            fixed: 'left'
        },
        {
            label: '行业大类',
            prop: 'industryCategories',
            width: 95
        },
        {
            label: '细分行业',
            prop: 'industryType',
            width: 75
        },
        {
            label: '客户系名称',
            prop: 'customerGroupName',
            width: 95
        },
        {
            label: '客户等级',
            prop: 'customerLevel',
            // type: 'select',
            optionsKey: 'SYS_WM_CUSTOMER_LEVEL',
            // options: {
            //     '2': '普通',
            //     '4': 'TOP'
            // },
            type: 'slot',
            editSlot: true,
            slot: true,
            width: 85
        },
        {
            label: '生效日期',
            prop: 'validTime',
            width: 105,
            sort: true
        },
        {
            label: '失效日期',
            prop: 'failTime',
            width: 105,
            sort: true
        },
        {
            label: '续签承接人',
            prop: 'renewalContractor',
            value: 'renewalContractor',
            name: '添加续签承接人',
            type: 'advance',
            // mustFill: true,
            advanceConfig: [
                { 'prop': 'esusUserNameCn', 'value': '姓名' },
                { 'prop': 'esusLoginName', 'value': '美信号' },
                { 'prop': 'esusId', 'value': 'ID' }
            ],
            tableConfig: [
                { 'prop': 'esusId', 'value': 'ID' },
                { 'prop': 'esusLoginName', 'value': '美信号' },
                { 'prop': 'esusUserNameCn', 'value': '姓名' },
                { 'prop': 'esusPositionName', 'value': '职位' },
                { 'prop': 'escoCompanyNameCn', 'value': '部门' }
            ],
            prefix: '/api-mdm',
            advanceUrl: `/selectUserCompany`,
            cbParams: ['esusUserNameCn#renewalContractor', 'esusLoginName#renewalContractorMip'],
            beforeRequest: (params) => {
                const inputKey = (params.esusUserNameCn ? params.esusUserNameCn : (params.esusLoginName ? params.esusLoginName : '')).trim();
                const reg = new RegExp('[\\u4E00-\\u9FFF]+', 'g'); // 中文字符正则
                if (reg.test(inputKey)) {
                    params.esusUserNameCn = inputKey;
                    params.esusLoginName = null;
                } else {
                    params.esusLoginName = inputKey;
                    params.esusUserNameCn = null;
                }
                return params;
            },
            width: 120
        },
        {
            label: '续签状态',
            prop: 'renewalStatus',
            type: 'select',
            optionsKey: 'CRM_CONTRACT_RENEWAL_STATUS',
            // mustFill: true,
            width: 100
        },
        {
            label: '续签风险',
            prop: 'renewalRisk',
            type: 'select',
            slot: true,
            optionsKey: 'CRM_CONTRACT_RENEWAL_RISK',
            width: 100
        },
        {
            label: '风险原因',
            prop: 'riskCause',
            type: 'select',
            multiple: true,
            optionsKey: 'CRM_CONTRACT_RENEWAL_RISK_CAUSE',
            width: 160
        },
        {
            label: '终止原因',
            prop: 'terminationCause',
            type: 'input',
            width: 140
        },
        {
            label: '主动/被动退出',
            prop: 'exitStatus',
            type: 'select',
            optionsKey: 'CRM_CONTRACT_EXIT_STATUS',
            width: 110
        },
        {
            label: '终止原因大类',
            prop: 'causeCategories',
            type: 'select',
            optionsKey: 'CRM_CONTRACT_CAUSE_CATEGORIES',
            width: 170
        },
        {
            label: '终止原因小类',
            prop: 'causeSubcategory',
            type: 'select',
            optionsKey: 'CRM_CONTRACT_CAUSE_SUBCATEGORY',
            width: 170
        },
        {
            label: '上游涨降',
            prop: 'upstreamRenewal',
            type: 'select',
            optionsKey: 'CRM_CONTRACT_RENEWAL_UPSTREAM',
            width: 160
        },
        {
            label: '上游涨降幅度(%)',
            prop: 'upstreamAmplitude',
            type: 'input',
            width: 150
        },
        {
            label: '下游涨降',
            prop: 'downstreamRenewal',
            type: 'select',
            optionsKey: 'CRM_CONTRACT_RENEWAL_DOWNSTREAM',
            width: 140
        },
        {
            label: '下游涨降幅度(%)',
            prop: 'downstreamAmplitude',
            type: 'input',
            width: 150
        },
        {
            label: '外部业务退出报告流程链接',
            prop: 'processLink',
            type: 'input',
            width: 170
        },
        {
            label: '提前终止时间',
            prop: 'earlyTerminationTime',
            type: 'date',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            width: 160
        },
        {
            label: '项目进度',
            prop: 'projectSchedule',
            // mustFill: true,
            type: 'input',
            width: 140
        },
        {
            label: '附件',
            prop: 'file',
            type: 'slot',
            slot: true,
            editSlot: true,
            width: 80
        }
    ],
    actions: { // 表格操作列的配置
        fixedWidth: 100, // fixedWidth（可选） 设置操作列的固定宽度，不加该参数默认是按钮list长度*50px
        list: [
            {
                label: '保存',
                event: 'saveRow',
                displayRule: (row) => {
                    // if (+row.edit === EDIT_TYPE.EDIT && btnPermissionsArr.includes('0808000002')) {
                    if (+row.edit === EDIT_TYPE.EDIT) {
                        return true;
                    }
                }
            }
        ]
    }
};
